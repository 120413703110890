@use '@keyliving/component-lib/dist/scss/mixins';
@use 'src/theme/common-style';
@use 'src/theme/font-family';
@use 'src/theme/colour';

.paymentHistoryWrapper {
    @include common-style.flexbox-column;

    padding: 1rem;
    background-color: #fff;

    .sectionHeader {
        padding: 1rem;
        font: 12px font-family.$raleway-bold;
        line-height: 14px;
        color: colour.$gray-5;
        letter-spacing: 1.13px;
    }
}

.button {
    width: 100%;
    margin: 0 auto;

    @include mixins.screen('md') {
        width: 250px;
    }
}

.paymentHistoryContent {
    padding: 1rem;

    table {
        width: 100%;

        tr {
            font: 16px font-family.$raleway-regular;
            line-height: 34px;
            @include common-style.flexbox-row;

            td {
                width: 25%;

                &:first-child {
                    text-align: start;
                }

                &:nth-child(2) {
                    text-align: center;
                    cursor: pointer;
                }

                &:nth-child(3) {
                    text-align: center;
                }

                &:last-child {
                    text-align: end;
                }
            }
        }
    }
}

.loadMoreButtonWrapper {
    width: 100%;
    padding: 2rem 1rem;
}
